import { render, staticRenderFns } from "./addRole.vue?vue&type=template&id=33b4c349&"
import script from "./addRole.vue?vue&type=script&lang=js&"
export * from "./addRole.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/1551980-cci-47740623-149755/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33b4c349')) {
      api.createRecord('33b4c349', component.options)
    } else {
      api.reload('33b4c349', component.options)
    }
    module.hot.accept("./addRole.vue?vue&type=template&id=33b4c349&", function () {
      api.rerender('33b4c349', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projectResourse/systemSettings/roleManagement/components/addRole.vue"
export default component.exports